import { styled } from '@mui/system'
import Button from '../../molecules/button'
import Tooltip from '../../molecules/tooltip'

const shouldForwardProp = (prop) => !['left', 'top', 'allPinCssProp', 'singlePinCssProp', 'aboveTheFold'].includes(prop)

const Root = styled('div')`
	width: 100%;
	position: relative;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const PinsWrapper = styled('div')`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9;
`

const Pin = styled('div', { shouldForwardProp })`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.palette.white.main};
	background-color: transparent;
	display: block;
	position: absolute;
	top: ${({ top }) => top}%;
	left: ${({ left }) => left}%;
	cursor: pointer;
	transition: all 0.2s ease;
	transform-origin: center;
	:after {
		content: '';
		display: block;
		width: 18px;
		height: 18px;
		background-color: ${({ theme }) => theme.palette.white.main};
		margin: 2px;
		border-radius: 50%;
		transition: all 0.2s ease;
		transform-origin: center;
	}
	${({ allPinCssProp }) => allPinCssProp};
	${({ singlePinCssProp }) => singlePinCssProp};
	&:hover {
		transform: scale(1.7);
		:after {
			transform: scale(0.6);
		}
	}
`

const ButtonClose = styled(Button)`
	color: ${({ theme }) => theme.palette.primary.main};
	margin: ${({ theme }) => theme.spacing(2, 2, 0, 'auto')};
	padding: ${({ theme }) => theme.spacing(0.5)};
	position: absolute;
	z-index: 10;
	right: 0;
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

const TooltipStyled = styled(Tooltip)``

export { Root, PinsWrapper, Pin, ButtonClose, TooltipStyled }
