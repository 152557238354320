import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import { useIsTouchDevice } from '@bluheadless/ui-logic/src/hooks/useIsTouchDevice'
import { cx } from '@emotion/css'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { array, object, string } from 'prop-types'
import { useState } from 'react'
import XIcon from '../../atoms/icons/x-icon'
import { ImageArtDirection, ImageBackgroundArtDirection } from '../../particles/art-direction'
import Block from '../../particles/cms-block/block'
import { ButtonClose, Pin, PinsWrapper, Root, TooltipStyled } from './image-map.styled'

const PinComponent = ({
	image,
	cmsBlockId,
	desktopCoords,
	mobileCoords,
	placement,
	pinsCssProps,
	singlePinCssProps,
}) => {
	const { content: cmsBlockContent } = useCmsBlock({ identifier: cmsBlockId })
	const [openDrawer, setOpenDrawer] = useState()
	const isTouch = useIsTouchDevice()
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))
	const coords = mdUp ? desktopCoords?.split(',') : mobileCoords?.split(',')

	const originalWidth = mdUp
		? image?.desktop?.originalWidth || image?.desktop?.imageProps?.originalWidth
		: image?.mobile?.originalWidth || image?.mobile?.imageProps?.originalWidth
	const originalHeight = mdUp
		? image?.desktop?.originalHeight || image?.desktop?.imageProps?.originalHeight
		: image?.mobile?.originalHeight || image?.mobile?.imageProps?.originalHeight

	const left = parseInt((coords[0] / originalWidth) * 100) ?? null
	const top = parseInt((coords[1] / originalHeight) * 100) ?? null
	return left && top && cmsBlockContent ? (
		<>
			<div style={{ display: 'none' }}>
				<Block content={cmsBlockContent} />
			</div>
			{isTouch ? (
				<>
					<Pin
						className="pin-item"
						left={left}
						top={top}
						onClick={() => setOpenDrawer(true)}
						allPinCssProp={pinsCssProps}
						singlePinCssProp={singlePinCssProps}
					/>
					<SwipeableDrawer
						open={openDrawer}
						onClose={() => setOpenDrawer(false)}
						anchor="bottom"
						disableSwipeToOpen
						PaperProps={{ sx: { background: 'transparent' } }}
					>
						<ButtonClose Icon={XIcon} onClick={() => setOpenDrawer(false)} variant="icon" />
						<Block content={cmsBlockContent} />
					</SwipeableDrawer>
				</>
			) : (
				<TooltipStyled title={<Block content={cmsBlockContent} />} placement={placement}>
					<Pin
						className="pin-item"
						left={left}
						top={top}
						allPinCssProp={pinsCssProps}
						singlePinCssProp={singlePinCssProps}
					/>
				</TooltipStyled>
			)}
		</>
	) : null
}

const ImageMap = ({ imageSources, pins, pinsCssProps, className, aboveTheFold }) => {
	const ImageComponent = aboveTheFold ? ImageBackgroundArtDirection : ImageArtDirection

	return pins?.length > 0 ? (
		<Root className={cx('ImageMap-root', className)} aboveTheFold={aboveTheFold}>
			<PinsWrapper>
				{pins.map(({ pinCmsBlockId, pinDesktopCoords, pinMobileCoords, placement, pinStyle }, index) => (
					<PinComponent
						key={index}
						cmsBlockId={pinCmsBlockId}
						desktopCoords={pinDesktopCoords}
						mobileCoords={pinMobileCoords}
						placement={placement}
						image={imageSources}
						pinsCssProps={pinsCssProps ?? null}
						singlePinCssProps={pinStyle ?? null}
					/>
				))}
			</PinsWrapper>
			<ImageComponent
				aboveTheFold={aboveTheFold}
				alt={null}
				sources={{
					xs: imageSources?.mobile || imageSources?.mobile?.imageProps,
					md: imageSources?.desktop || imageSources?.desktop?.imageProps,
				}}
			/>
		</Root>
	) : null
}

ImageMap.propTypes = {
	imageSources: object,
	pins: array.isRequired,
	pinsCssProps: string,
}

export default ImageMap
